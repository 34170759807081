var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
export default function Drawer(theme) {
    var isLight = theme.palette.mode === 'light';
    return {
        MuiDrawer: {
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return (__assign({}, (ownerState.variant === 'temporary' && {
                        '& .MuiDrawer-paper': {
                            boxShadow: "-40px 40px 80px -8px ".concat(alpha(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.24)),
                        },
                    })));
                },
            },
        },
    };
}
